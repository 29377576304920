<template>
  <span :class="$style.container" :style="containerStyle">
    <span :class="$style.placeholder" :style="placeholderStyle"/>
    <Loading v-if="!isImageLoaded" :class="$style.loading"/>
    <img :class="$style.img" v-if="shouldDisplayImage" :src="src" :alt="computedAlt" loading="lazy" @load="onLoad"/>

    <LazyHydrate v-if="!isMounted" never>
      <noscript>
        <img :class="$style.img" :src="src" :alt="alt" loading="lazy"/>
      </noscript>
    </LazyHydrate>
  </span>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

import Loading from '@/components/Loading'

export default {
  name: 'Img',
  components: {
    LazyHydrate,
    Loading,
  },
  props: {
    alt: String,
    data: Object,
    height: Number,
    width: Number,
  },
  data() {
    return {
      devicePixelRatio: 1,
      isImageLoaded: false,
      isMounted: false,
      observer: null,
      shouldDisplayImage: false,
    }
  },
  computed: {
    computedAlt() {
      return this.alt || this.data.alt
    },
    computedColor() {
      return !this.isImageLoaded ? this.$store.state.imageColor[this.data.url] : null
    },
    containerStyle() {
      let width = null

      if (this.width) {
        width = `${this.width}px`
      }

      if (this.height) {
        width = `${this.height / this.ratio}px`
      }

      return {
        width,
      }
    },
    placeholderStyle() {
      return {
        backgroundColor: this.computedColor,
        paddingTop: `${100 * this.ratio}%`,
      }
    },
    ratio() {
      if (this.height && this.width) {
        return this.height / this.width
      }

      return (this.data.dimensions?.height || this.data.height) / (this.data.dimensions?.width || this.data.width)
    },
    src() {
      let src = this.data.url

      if (this.width) {
        src += `&w=${this.width * this.devicePixelRatio}`
      }

      if (this.height) {
        src += `&h=${this.height * this.devicePixelRatio}`
      }

      if (this.width && this.height) {
        src += '&fit=crop'
      }

      return src
    },
  },
  serverPrefetch() {
    return this.fetchImageColor()
  },
  mounted() {
    this.isMounted = true
    this.devicePixelRatio = window.devicePixelRatio || 1
    this.fetchImageColor()
    this.observeElement()
  },
  beforeDestroy() {
    this.observer.disconnect()
  },
  methods: {
    async fetchImageColor() {
      if (this.isImageLoaded) return
      if (this.$store.state.imageColor[this.data.url]) return

      const response = await fetch(`${this.data.url}&palette=json`)
      const json = await response.json()

      const color = json.dominant_colors.vibrant?.hex || json.dominant_colors.vibrant_light?.hex

      this.$store.commit('setImageColor', { key: this.data.url, value: color })
    },
    observeElement() {
      if (
        !('IntersectionObserver' in window) ||
        !('IntersectionObserverEntry' in window) ||
        !('isIntersecting' in window.IntersectionObserverEntry.prototype)
      ) {
        this.shouldDisplayImage = true

        return
      }

      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) return

          observer.disconnect()
          this.shouldDisplayImage = true
        })
      })

      this.observer.observe(this.$el)
    },
    onLoad() {
      this.isImageLoaded = true
    },
  },
}
</script>

<style module lang="scss">
.container {
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.placeholder {
  display: block;
}

.loading {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.img {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
