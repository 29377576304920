// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Img_container_1SKPb{display:inline-block;position:relative;vertical-align:top}.Img_placeholder_XlAlV{display:block}.Img_loading_3Jt5F{left:50%;top:50%;transform:translate(-50%,-50%)}.Img_img_2ccID,.Img_loading_3Jt5F{position:absolute}.Img_img_2ccID{left:0;top:0;width:100%}", ""]);
// Exports
exports.locals = {
	"container": "Img_container_1SKPb",
	"placeholder": "Img_placeholder_XlAlV",
	"loading": "Img_loading_3Jt5F",
	"img": "Img_img_2ccID"
};
module.exports = exports;
