<template>
  <Section compact>
    <H :like="2">Gallery</H>

    <Loading v-if="computedAreItemsLoading"/>

    <ul :class="$style.ul">
      <li :class="$style.li" v-for="({ media, title }, key) in computedItems" :key="key">
        <Img :class="$style.img" v-if="media._linkType === 'Link.image'" :data="media" :alt="title" :width="266" :height="266"/>
      </li>
    </ul>
  </Section>
</template>

<script>
import gql from 'graphql-tag'

import H from '@/components/H'
import Img from '@/components/Img'
import Loading from '@/components/Loading'
import Section from '@/components/Section'

export default {
  name: 'Gallery',

  components: {
    H,
    Img,
    Loading,
    Section,
  },

  data() {
    return {
      dataDescription: 'a creative wizard who dabbles in the digital arts of frontend web development',
    }
  },

  computed: {

    computedAreItemsLoading() {
      return this.$apollo.queries.allGallerys.loading
    },

    computedDescription() {
      return `${this.dataDescription[0].toUpperCase()}${this.dataDescription.slice(1)}.`
    },

    computedItems() {
      return this.allGallerys?.edges[0].node.items
    },

  },

  metaInfo() {
    return {
      meta: [{ name: 'description', content: this.computedDescription }]
    }
  },

  apollo: {
    allGallerys: gql`
      {
        allGallerys {
          edges {
            node {
              items {
                media {
                  _linkType
                  ...on _FileLink {
                    name
                    size
                    url
                  }
                  ...on _ImageLink {
                    height
                    name
                    size
                    url
                    width
                  }
                }
                title
              }
            }
          }
        }
      }
    `,
  },
}
</script>

<style module lang="scss">
.ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

.li {
  box-sizing: border-box;
  padding-left: 1px;
  margin-top: 1px;
  width: 100%;

  @media(min-width: 400px) {
    width: 100% * (1 / 2);
  }

  @media(min-width: 611px) {
    width: 100% * (1 / 3);
  }
}

.img {
  max-width: 100%;
}
</style>
