// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Gallery_ul_2QyQX{display:flex;flex-wrap:wrap;list-style:none;margin-bottom:0;margin-top:0;padding-left:0}.Gallery_li_1dIo1{box-sizing:border-box;padding-left:1px;margin-top:1px;width:100%}@media(min-width:400px){.Gallery_li_1dIo1{width:50%}}@media(min-width:611px){.Gallery_li_1dIo1{width:33.3333333333%}}.Gallery_img_3rijn{max-width:100%}", ""]);
// Exports
exports.locals = {
	"ul": "Gallery_ul_2QyQX",
	"li": "Gallery_li_1dIo1",
	"img": "Gallery_img_3rijn"
};
module.exports = exports;
